import HelperService from "@/services/HelperService";
import axios from "@axios";

const baseUrl = "/admin/news-feeds";

export default {
  async listNewsFeeds(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`)
  },
  async createNewsFeed(data) {
    return axios.post(`${baseUrl}`, data);
  },
  async updateNewsFeed(id, data) {
    return axios.put(`${baseUrl}/${id}`, data);
  },
  async deleteNewsFeed(id) {
    return axios.delete(`${baseUrl}/${id}`);
  }
}
