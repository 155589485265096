<script>
import helperService from "@/services/HelperService";
import AdminNewsFeedsService from "@/services/AdminNewsFeedsService";
import CreateNewsFeedModal from "@/views/kiosk/viewKioskNewsFeeds/EditNewsFeedModal";
import DeleteModal from "@/components/modals/DeleteModal";
import EnterpriseService from "@/services/EnterpriseService";

export default {
  name: "KioskNewsFeeds",
  components: {
    CreateNewsFeedModal,
    DeleteModal,
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      total_count: 0,
      enterpriseTotalCount: 0,
      hasSearched: false,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      enterpriseFilters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      feeds: [],
      selectedFeed: null,
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "url",
          label: "URL",
        },
        {
          key: "enterpriseName",
          label: "Enterprise",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      newsFeedDto: {},
      enterprises: [],
      editModalId: "modal-edit",
      deleteModalId: "modal-delete",
    };
  },
  async mounted() {
    await this.getEnterprises();
    await this.getNewsFeeds();
    this.loading = false;
  },
  methods: {
    async getEnterprises() {
      try {
        const {data} = await EnterpriseService.getEnterprises(this.enterpriseFilters);
        this.enterprises = data.data;
      } catch (e) {
        //console.error("Error in getEnterprises: ", e);
        helperService.showNotfyErr(
          this.$toast,
          error,
          "Error getting news feeds, please refresh and try again.",
        );
      }
    },
    getEnterpriseNameFromUid(enterpriseUid) {
      try {
        const enterprise = this.enterprises.find(e => e.uid === enterpriseUid);
        return enterprise ? enterprise.display_name : "Global";
      } catch (e) {
        //console.error("Error in getEnterpriseNameFromUid: ", e);
        return "Unknown"
      }
    },
    async getNewsFeeds() {
      try {
        const {data} = await AdminNewsFeedsService.listNewsFeeds(this.filters);
        this.feeds = data.data;
        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
        if (this.filters.search) {
          this.hasSearched = true;
        }
      } catch (error) {
        //console.error("Error in getNewsFeeds: ", error);
        helperService.showNotfyErr(
          this.$toast,
          error,
          "Error getting news feeds, please refresh and try again.",
        );
      }
    },
    getMoreFeeds(page) {
      this.filters.page = page - 1;
      this.getNewsFeeds();
    },
    refreshFeeds() {
      this.selectedFeed = null;
      this.closeModals();
      this.getMoreFeeds(1);
    },
    clearSearch() {
      this.filters.search = null;
      if (!this.hasSearched) {
        return;
      }
      this.getMoreFeeds(1);
    },
    async deleteFeed() {
      try {
        if (!this.selectedFeed) {
          return;
        }
        await AdminNewsFeedsService.deleteNewsFeed(this.selectedFeed.id);
        helperService.showNotfySuccess(this.$toast, "News feed successfully deleted");
        this.refreshFeeds();
      } catch (error) {
        //console.error("Error deleting news feed: ", error);
        helperService.showNotfyErr(this.$toast, "Error deleting news feeds");
      }
    },
    showModal(feed, modalId) {
      this.selectedFeed = feed;
      this.$bvModal.show(modalId);
    },
    closeModals() {
      this.$bvModal.hide(this.editModalId);
      this.$bvModal.hide(this.deleteModalId);
    },
  },
};
</script>

<template>
  <div id="kiosk-news-feeds-page">
    <b-card title="Manage News Feeds">
      <b-card-text>
        This page display all of the potential news feeds available in the kiosk application. Manage the feeds by
        adding, removing, or updating the information.
      </b-card-text>
    </b-card>
    <b-card no-body>
      <div class="d-flex flex-row align-items-center justify-content-between p-2">
        <b-col cols="12" md="4" class="p-0">
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              placeholder="Search feeds by name..."
              @keydown.enter="getMoreFeeds(1)"
            />
            <b-input-group-append v-if="filters.search">
              <b-button
                variant="outline-secondary"
                @click="clearSearch"
              >
                Clear
              </b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button variant="primary" @click="getMoreFeeds(1)">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-btn variant="success" @click="showModal(null, editModalId)">
          Add Feed
        </b-btn>
      </div>
      <b-table
        v-if="!loading"
        class="data-table"
        hover
        :items="feeds"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        fixed
      >
        <template #cell(name)="data">
          <div v-b-tooltip.hover="data.item.name" class="multiline-ellipsis">
            {{ data.item.name }}
          </div>
        </template>
        <template #cell(url)="data">
          <div v-b-tooltip.hover="data.item.url" class="multiline-ellipsis">
            {{ data.item.url }}
          </div>
        </template>
        <template #cell(enterpriseName)="data">
          <div>{{ getEnterpriseNameFromUid(data.item.enterprise_uid) }}</div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v" />
            </template>
            <b-dropdown-item-button class="full-nested-btn" @click="showModal(data.item, editModalId)">
              Update
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" @click="showModal(data.item, deleteModalId)">
              Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row class="mx-1 justify-content-end">
        <b-col v-if="total_count > filters.page_size" cols="auto" class="mt-1">
          <b-pagination
            v-model="currentPage"
            class="p-0"
            :total-rows="total_count"
            :per-page="filters.page_size"
            @change="getMoreFeeds"
          />
        </b-col>
        <b-col v-if="total_count === 0" cols="12" class="mt-1">
          <p>No feeds found...</p>
        </b-col>
      </b-row>
    </b-card>

    <b-modal :id="editModalId" :title="selectedFeed ? 'Update' : 'Create' + 'news feed'" hide-footer>
      <create-news-feed-modal
        :selected-feed="selectedFeed"
        :enterprises="enterprises"
        @close="closeModals"
        @refresh="refreshFeeds"
      />
    </b-modal>

    <b-modal :id="deleteModalId" title="Delete a news feed" hide-footer>
      <delete-modal
        v-if="selectedFeed"
        title="Are you sure you wish to delete this feed?"
        :subtitle="selectedFeed.name"
        @close="closeModals"
        @delete="deleteFeed"
      />
    </b-modal>
  </div>
</template>

<style scoped>
.multiline-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
