<script>
import NewsFeedsService from "@/services/AdminNewsFeedsService";
import helperService from "@/services/HelperService";

export default {
  name: "EditNewsFeedModal",
  props: {
    selectedFeed: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    enterprises: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  data() {
    return {
      submitting: false,
      newsFeedDto: {
        enterprise_uid: this.selectedFeed ? this.selectedFeed.enterprise_uid : "",
        name: this.selectedFeed ? this.selectedFeed.name : "",
        url: this.selectedFeed ? this.selectedFeed.url : "",
      },
    };
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    async createNewsFeed() {
      try {
        if (this.submitting) {
          return;
        }
        this.submitting = true;
        await NewsFeedsService.createNewsFeed(this.newsFeedDto);
        helperService.showNotfySuccess(
          this.$toast,
          "News feed created."
        )
        this.$emit('refresh')
      } catch (error) {
        helperService.showNotfyErr(
          this.$toast,
          error,
          "Error creating news feed, please check and try again.",
        )
      } finally {
        this.submitting = false;
      }
    },
    async updateNewsFeed() {
      try {
        if (this.submitting) {
          return;
        }
        this.submitting = true;
        await NewsFeedsService.updateNewsFeed(this.selectedFeed.id, this.newsFeedDto);
        helperService.showNotfySuccess(
          this.$toast,
          "News feed updated."
        )
        this.$emit('refresh')
      } catch (error) {
        helperService.showNotfyErr(
          this.$toast,
          error,
          "Error updating news feed, please check and try again.",
        )
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>

<template>
  <div id="create-news-feed-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(selectedFeed ? updateNewsFeed : createNewsFeed)">
        <b-form-group>
          <b-input-group class="d-flex flex-column mb-2">
            <label>Name</label>
            <validation-provider v-slot="validationContext" name="feed name" :rules="{ required:true }">
              <b-form-input
                v-model="newsFeedDto.name"
                placeholder="Enter a name"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-input-group>
          <b-input-group class="d-flex flex-column mb-2">
            <label>URL</label>
            <validation-provider v-slot="validationContext" name="feed url" :rules="{ required:true }">
              <b-form-input
                v-model="newsFeedDto.url"
                placeholder="Enter the RSS URL"
                :style="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-input-group>
          <b-input-group v-if="!selectedFeed" class="d-flex flex-column mb-2">
            <label>Enterprise</label>
            <div class="d-flex align-items-center">
              <b-form-select id="enterprise" v-model="newsFeedDto.enterprise_uid">
                <b-form-select-option value="">Global (all enterprises)</b-form-select-option>
                <b-form-select-option v-for="(item, i) in enterprises" :key="i" :value="item.uid">
                  {{ item.display_name }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </b-input-group>
          <b-input-group class="d-flex flex-row-reverse">
            <b-btn variant="primary" type="submit" :disabled="invalid || submitting">{{ selectedFeed ? 'Update' : 'Create' }} News Feed</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>
